/* You can add global styles to this file, and also import other style files */
@import "colors";

@font-face {
  font-family: "Roboto Light";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f6f6f6;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

div.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #00000026;
}

mat-sidenav-container.mat-drawer-container {
  background-color: #F6F6F6;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: $color-blue-interactive-bas1;
}

.ren-list-filter-option.accessible-filter-option {
  .mdc-list-item__primary-text {
    color: #707070;
    font-size: 14px;
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $color-blue-interactive-bas1;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: $color-blue-interactive-bas1;
}

.accessible-filters {
  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-select-arrow-wrapper {
    margin-right: 8px;
  }

  .mat-mdc-select-value {
    margin-left: 4px;
    color: #707070;
    font-size: 14px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    background-color: white;
    max-height: 50px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }
}

.accessible-dropdown {
  .mat-mdc-text-field-wrapper {
    background-color: white;
    max-height: 38px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: auto;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused) {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: #737373;
    }
  }

  --mdc-theme-primary: $color-blue-interactive-bas1;
  --mdc-theme-secondary: $color-blue-interactive-bas1;
}

.accessible-selector {
  .mdc-list-item--selected {
    --mdc-theme-primary: $color-blue-interactive-bas1;
    --mdc-theme-secondary: $color-blue-interactive-bas1;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: $color-blue-interactive-bas1;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled),
  .mat-mdc-option:focus:not(.mdc-list-item--disabled),
  .mat-mdc-option.mat-mdc-option-active {
    text-decoration: underline;
  }
}

.accessible-filters{
  .mat-mdc-text-field-wrapper {
    max-height: 30px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 3px;
  }

  .mat-mdc-select-placeholder{
    color: #555555
  }
}

mat-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.header-dropdown-options.mat-mdc-menu-panel {
  margin-top: 15px;
}

.header-dropdown-option {
  .mat-mdc-menu-item{
    &:hover,&:focus-visible {
      background-color: transparent;
      text-decoration: underline;

      .mdc-list-item__primary-text {
        color: $color-blue-interactive-bas1;
      }
    }
  }
  .mdc-list-item__primary-text {
    color: #737373;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $color-blue-interactive-bas1;
}

// Override the built in mat dialog, but guard it with .rounded-dialog
.rounded-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

.search-results-tr {
  outline: none;
}

// Modal dialog
.cdk-overlay-pane:has(.cdk-dialog-container) {
  max-height: 85vh !important;
  max-width: 85vw !important;
}

// Dropdown list
.cdk-overlay-pane .mdc-menu-surface.mat-mdc-select-panel {
  max-height: 50vh;
  padding: 6px 0;
}

// Dropdown list item
.cdk-overlay-pane .mat-mdc-option {
  min-height: calc(50vh/10 + 18px);
  //calculate min height on different zoom level and screen size
  // 50vh/10 + 18px
  //   -> dynamically get the 50% height of view port and divide it by 10 to get the height of each item and added 18px for padding
}

// Mat Expansion - arrow indicator focus effect on tab press
.mat-expansion-panel:not(:has(.mat-content-hide-toggle))
  .mat-expansion-panel-header:focus::after {
    content: '';
    display: inline-block;
    width: 12px;
    border-bottom: 2px solid #146eb3;
    margin: 17px 2px 0 -12px;
}
.mat-expansion-panel.mat-expanded:not(:has(.mat-content-hide-toggle))
  .mat-expansion-panel-header:focus::after {
    margin-top: 6px;
}
.mat-expansion-indicator::after {
  margin: 0 2px;
}
