// Global color values based on:
// https://illuminate.atlassian.net/wiki/spaces/DS/pages/17174536026/Colors

$bas0: #202020; // body
$bas1: #146EB3; // interactive blue
$bas5: #FF8300; // warning
$bas7: #CF3A4E; // website red
$gra3: #E5E5E5; // border light
$gra4: #CCCCCC; // border
$gra5: #ADADAD; // border color
$gra6: #707070; // helper text
$gra8: #F3F5F7; // page background
$gra9: #555555; // activity feed color



// Aliases:
$color-black-body-bas0:            $bas0;
$color-blue-interactive-bas1:      $bas1;
$color-orange-warning-bas5:        $bas5;
$color-red-website-bas7:           $bas7;

$color-gray-border-light-gra3:     $gra3;
$color-gray-border-gra4:           $gra4;
$color-gray-helper-text-gra6:      $gra6;
$color-gray-page-background-gra8:  $gra8;
$color-gray-page-border-gra5:      $gra5;
$color-gray-activity-feed-gra9:    $gra9;
